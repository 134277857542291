/* global Vue */
import EventCalendar from './EventCalendar'
import { today, currentMonth } from './now'

function init () {
	const container = document.querySelector('.ho-event-cnt')
	if (!container) return

	const sourceUrl = container.getAttribute('data-src-url') || ''
	const month = readMonth(container.getAttribute('data-month')) || currentMonth

	const app = new Vue({
		el: container,
		components: {
			EventCalendar,
		},
		data () {
			return {
				props: {
					sourceUrl,
					month,
					today,
				}
			}
		},
		template: '<EventCalendar v-bind="props" />'
	})
	return app
}

function readMonth (monthStr) {
	if (!monthStr) return null
	const match = monthStr.match(/^([0-9]{4})[/-]([0-9]{2})\b/)
	if (!match) return null
	return new Date(parseInt(match[1], 10), parseInt(match[2], 10) - 1, 1)
}

init()
