/* global zzl */
import Calendar from './Calendar'
import EventSource from './EventSource'
import { today, currentMonth } from './now'

export default {
	name: 'EventCalendar',
	props: {
		sourceUrl: {
			type: String,
		},
		month: {
			type: Date,
			default: currentMonth
		},
		today: {
			type: Date,
			default: today
		}
	},
	data () {
		const highlights = []
		return {
			min: currentMonth,
			max: currentMonth,
			eventSource: new EventSource(this.sourceUrl),
			aMonth: this.month,
			attrs: highlights,
			highlights
		}
	},
	components: {
		Calendar
	},
	template: `
		<div class="event-calendar">
			<Calendar
				:month="month"
				:min="min"
				:max="max"
				:today="today"
				:attrs="attrs"
				@setMonth="setMonth"
				@click.native="handleClick"
			/>
			<div class="event-calendar-legend-list">
				<div class="event-calendar-legend-item">
					<span class="event-calendar-legend-bar event"></span>
					イベントがある日
				</div>
			</div>
		</div>
	`,
	mounted () {
		this.eventSource.fetch(this.month, event => {
			if (event.dateMax) {
				this.max = event.dateMax
			}
			this.setEventHighlights(event.highlights)
		})
	},
	methods: {
		setMonth (month) {
			this.aMonth = month
			this.eventSource.preload(month)
			this.eventSource.fetch(month, event => {
				this.setEventHighlights(event.highlights)
			})
		},
		setEventHighlights (highlights) {
			const month = this.aMonth
			this.highlights.splice(0, this.highlights.length)
			highlights.forEach(item => {
				this.highlights.push({
					highlight: item.highlight,
					dates: item.dates.filter(date => date.getFullYear() === month.getFullYear() && date.getMonth() === month.getMonth())
				})
			})
		},
		handleClick (event) {
			if (!event.target || !event.target.classList) return
			const { target } = event
			const cl = target.classList
			const pcl = target.parentNode.classList
			if (cl.contains('bs-cal-title')) {
				this.navigateToMonthlyEventList(this.aMonth)
			} else if (cl.contains('bs-cal-num') && pcl.contains('bs-cal-col-hl-event') && !pcl.contains('bs-cal-col-hl-closed')) {
				const datestr = target.getAttribute('data-date')
				if (!datestr) return
				this.navigateToDailyEventList(datestr)
			}
		},
		navigateToMonthlyEventList (month) {
			location.href = this.createURL('/' + month.getFullYear() + '/' + ('0' + String(month.getMonth() + 1)).slice(-2) + '/')
		},
		navigateToDailyEventList (datestr) {
			location.href = this.createURL('/' + datestr.replace(/-/g, '/') + '/')
		},
		createURL (url) {
			return this.sourceUrl + url
		}
	}
}
